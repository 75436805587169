import React from 'react';
import ReactDOM from 'react-dom/client';
import { render } from "react-dom";
import { BrowserRouter, Route, Link, Routes, Switch, HashRouter} from 'react-router-dom';

import './index.css';
import Home from './Layout/Pages/Home';
import Serial from './Layout/Pages/Verify/Serial';
import Serialnumber from './Layout/Pages/Verify/Serialnumber';
import Serialnumbergenuine from './Layout/Pages/Verify/Serialnumbergenuine';
import reportWebVitals from './reportWebVitals';
import Serialgp10 from './Layout/Pages/gp10/Serialgp10';
import Serialnumbergp10 from './Layout/Pages/gp10/Serialnumbergp10';
import Serialnumbergenuinegp10 from './Layout/Pages/gp10/Serialnumbergenuinegp10';
import Homeg10 from './Layout/Pages/gp10/Homeg10';
import Nodata from './Layout/Pages/gp10/Nodata';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/:qrCode" element={<Serial />} /> 
          <Route path="/serial" element={<Serialnumber />} /> 
          <Route path="/not-found/" element={<Serialnumbergenuine />} /> 
          
          <Route path="/gp10/" element={<Homeg10 />} /> 
          <Route path="/gp10/:qrCode" element={<Serialgp10 />} />
          <Route path="/gp10/serial" element={<Serialnumbergp10 />} /> 
          <Route path="/gp10/not-found/:qrCode" element={<Serialnumbergenuinegp10 />} /> 
          <Route path="/gp10/invalid-coupon-code/" element={<Nodata />} /> 


      </Routes>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
