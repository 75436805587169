import React,  { Component, useState }from 'react';
import '../../Common/Custom.css';
import { BrowserRouter , Link, NavLink, useNavigate, withRouter  } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import DvrIcon from '@mui/icons-material/Dvr';
import EventIcon from '@mui/icons-material/Event';
import MoneyIcon from '@mui/icons-material/Money';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import PlaceIcon from '@mui/icons-material/Place';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Divider } from '@mui/material';
import TextField from '@mui/material/TextField';
import CelebrationIcon from '@mui/icons-material/Celebration';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import CallIcon from '@mui/icons-material/Call';

import Form from './Form';


 
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  boxShadow:'none',
}));





class Serialnumber extends Component {
  

  producthistory = () => {
    fetch('https://dialerpwebapis-dev-as.azurewebsites.net/api/WebApi/AddGetScanHistory?cIp=""&qrCode=&latitude=""&longitude=""&parentID=40010', {
      method: "POST",
      headers: {
        "Authorization-Token":'J47OVAUbs5z5vMuhENUTpzfq/jxCrnqid55BpzdYm73bkgvYoi6YT8OH9Wulh1jE+nRaS3+eM1A/MlsDFraz2ECW4dDzC3Ff50WXsR7PB4qzu3h3QkHHwHusnYMVMl6uXQm+jj1IG1VPKw97uPHW5eWJ5n/5OTjW2yKkclTnmGzuVsHhHqm33nIFZiRE0gbrCZ6RXt5pxvty5+PBHQq1PJ91P/c2ZmWLjhGH/BbFSRvPsJZL9dDzOMCOw7WQYlj7pHRWWb8nW/czQhr53uQLpSY2fc4Dqj27xdMH+shxkF7TfIxN98QIYAoCIhLWlskE7+lpT/0J7gHISXQ1q3uVIFBq96cuyw+uaBuGdjB4HZr80+adCDDa+aQhly2NMp57MG/8du5B4yN0JY5EGwth9dsNUZvea6ozKyERoLbmsOA3gyQndWaOiUI1l254xV0i2LpvNDJccqHDIYlb+5QYAw==',
        Accept: "application/json, text/plain",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(this.state),
    }).then((result) => {
      result.json().then((resp) => {
        this.setState({ datas: resp });
        console.log(result, 'dataas')
      });
    });
  };


  

  
  componentDidMount(){
    this.producthistory();
  } 

render(){
  
 const datas = this.state;
 console.log(datas, "this is product history");


  return <div><div className="verify-product">
      <div className="product-box">
       <Card className='card-box'>
          <Grid container spacing={2} className='mb-10'>
            <Grid item xs={12}>
            {
        this?.state?.datas?.response.objlist.map((item)=>{
         return (
              <Item><h3> {item.itemName}</h3></Item>
              
         )
        })
       }
            </Grid>
          </Grid>
          <Card className='inner-card mb-10'>
          <Grid container spacing={2}>
          
            <Grid item md={5} xs={12}>
              <Item><img src={process.env.PUBLIC_URL + '/images/file-1660022359197.png'} alt="Slide" style={{width: '200px'}} /></Item>
            </Grid>
            <Grid item md={7} xs={12}>
              <Item>
              <div className='scan-status genuine'><CelebrationIcon/>Genuine Product</div>
            
                <Divider/>
      {
        this?.state?.datas?.response.objlist.map((item)=>{
         return (
               <ul className='product-detail-lst'>
                  {/*<li key="{item}"><DvrIcon /> Batch No : {item.batchNo}</li>
                  <li key="{item1}"><EventIcon /> Expiry Date : {item.expiryDt}</li>
                  <li key="{item2}"><MoneyIcon /> MRP : {item.mrp}</li>
         <li key="{item3}"><WarehouseIcon /> Manufacture Date : {item.createdAt}</li>*/}
                  <li key="{item4}"><PlaceIcon /> Produced At : {item.plantName}</li>
               </ul>
         )
        })
       }
              </Item>
            </Grid>
          </Grid>
          </Card>


          <Card className='inner-card mb-10'>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <Item>
              <div className='scantitle'>Scan Hisitory</div>
                <Divider/>
      {
        this?.state?.datas?.response.scanHistorylist.map((items)=>{
         return (
               <ul className='product-detail-lst scanhistorylist'>
                  <li key="{items}"><EventIcon /> Scan Date : {items.scanDt}</li>
                  <li key="{items1}"><AccessTimeIcon /> Scan Time : {items.scanTime}</li>
                  <li key="{items2}"><CardMembershipIcon /> IP Address : {items.cIp}</li>
               </ul>
         )
        })
       }

              </Item>
            </Grid>
          </Grid>
          </Card>


          <Card className='inner-card mb-10'>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <Item>
              <div className='helplinetitle'>Helpline For Any Query  <a href="tel:1800-102-8397"><CallIcon/> 1800-102-8397</a></div>

              </Item>
            </Grid>
          </Grid>
          </Card>


       </Card>

       <Grid container spacing={2} className="mb-20 mb-auto button-group">          
          <Grid item xs={12}>
                <Stack spacing={2} direction="row" className="stacks-button-group">
                <HashLink smooth to="#id-1" activeclassname="active"> <Button variant="outlined">Discover</Button></HashLink>
                <HashLink smooth to="#id-2" activeclassname="active"> <Button variant="outlined">Learn</Button></HashLink>
                    <a href="https://ananda.in/product/rich-paneer/" target="_blank"><Button variant="outlined">Buy</Button></a>
                    <HashLink smooth to="#id-3" activeclassname="active"> <Button variant="outlined">Connect</Button></HashLink>
                </Stack>
          </Grid>
         </Grid>

       <Card className='inner-card discover mb-10' id="id-1">
       
          <Grid container spacing={2}>
          
            <Grid item md={6} xs={12}>
              <Item>
                  <Card>
                  <p className='video-title'>See how your favourite Ghee is prepared?</p>
                  <iframe width="94%" height="180px" src="https://www.youtube.com/embed/yRUUSoxHHs0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                  </Card>
                </Item>
            </Grid>
            <Grid item md={6} xs={12}>
              <Item>                
                <Card>
                  <p className='video-title'>Ananda Business Associates Awards</p>
                  <iframe width="94%" height="180px" src="https://www.youtube.com/embed/XXDx2LxF9C8" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                  </Card>
              </Item>
            </Grid>
            <Grid item md={6} xs={12}>
              <Item>                
                <Card>
                  <p className='video-title'>Besan ke laddu with Ananda Ghee</p>
                  <iframe width="94%" height="180px" src="https://www.youtube.com/embed/Gqnc7TtmtE4" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </Card>
              </Item>
            </Grid>
            <Grid item md={6} xs={12}>
              <Item>                
                <Card>
                  <p className='video-title'>Our other products</p>
                  <a href="https://ananda.in/main-category/" target="_blank"><img src={process.env.PUBLIC_URL + '/images/ananda-products.webp'} alt="Slide" style={{width: '96%'}} /></a>
                </Card>
              </Item>
            </Grid>
            
          </Grid>
          </Card>

          <Card className='inner-card learn mb-10' id="id-2">
       
       <Grid container spacing={2}>
       
         <Grid item xs={12}>
           <Item>
              <h3 className='sec-title'>Learn</h3>
               <p className='video-title'>Ananda Ghee is an ethnic product made by our dairies with decades of experience. Our Ghee is a rich source of vitamins A and D, antioxidants, Omega 3 and CLA, essential for vision, body growth, bone metabolism and immune system.

</p>
              
             </Item>
         </Grid>
       </Grid>
       </Card>

       

      </div> 
    </div>
    </div>
}
}


export default Serialnumber;
